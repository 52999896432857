<template>
  <b-card class="p-3">
    <go-back
      title="Detalle de paquete"
      class="mb-3"
      @back-action="$router.go(-1)"
    />
    <parcel-detail />
  </b-card>
</template>

<script>
import ParcelDetail from "./ParcelDetail";
import GoBack from "@zubut/common/src/components/GoBackTitle";

export default {
  name: "ParcelDetailMobile",

  components: {
    GoBack,
    ParcelDetail
  }
};
</script>
