<template>
  <div>
    <parcel-detail-header
      class="d-print-none"
      :parcel="parcel"
      @show-evidence="showParcelEvidenceModal = true"
    />
    <div v-if="loading">
      <loading-spinner />
    </div>
    <div
      v-else
      class="parcel-detail-flex d-lg-flex justify-content-between mt-4"
    >
      <parcel-detail-card class="w-100" :delivery="parcel" />
      <z-tabs pills class="parcel-detail-tabs border rounded w-100">
        <b-tab title="Mapa" lazy>
          <div class="mt-3 justify-sm-content-around">
            <parcel-detail-map
              :destination="parcel.destination"
              :origin="parcel.origin"
            />
          </div>
        </b-tab>
        <b-tab title="Registros" lazy>
          <div>
            <parcel-detail-logs
              class="mt-4"
              :parcel-id="selectedParcelId"
              :origin="parcel.origin"
              :destination="parcel.destination"
              :warehouse-id="parcel.warehouseId"
            />
          </div>
        </b-tab>

        <b-tab title="Guía" lazy>
          <parcel-detail-shipping-label class="mt-4" :parcel-id="parcel.id" />
        </b-tab>
      </z-tabs>
    </div>
    <parcel-evidence
      v-if="showParcelEvidenceModal"
      :show.sync="showParcelEvidenceModal"
      :parcel-id="selectedParcelId"
    />
  </div>
</template>

<script>
import ParcelDetailCard from "./ParcelDetailCard";
import ParcelDetailHeader from "./ParcelDetailHeader";
import ParcelDetailLogs from "./ParcelDetailLogs";
import Parcels from "@/services/parcels";
import ParcelEvidence from "@/app/components/ParcelEvidence";
import ParcelDetailMap from "./ParcelDetailMap";
import ParcelDetailShippingLabel from "./ParcelDetailShippingLabel";
import ZTabs from "@zubut/common/src/components/ZTabs";
import Http from "@zubut/common/src/constants/http";

export default {
  name: "ParcelDetail",

  components: {
    ParcelDetailCard,
    ParcelEvidence,
    ParcelDetailHeader,
    ParcelDetailLogs,
    ParcelDetailMap,
    ParcelDetailShippingLabel,
    ZTabs
  },

  props: {
    parcelId: {
      type: String,
      default: ""
    },
    show: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      parcel: null,
      loading: true,
      showParcelEvidenceModal: false
    };
  },

  computed: {
    selectedParcelId() {
      // parcelId for modal, $route.params.id for the route
      return this.parcelId || this.$route.params.id;
    }
  },

  watch: {
    parcelId: {
      handler(newVal) {
        if (newVal) {
          this.getParcel();
        }
      }
    }
  },

  created() {
    this.getParcel();
  },

  methods: {
    hide() {
      this.$emit("update:show", false);
    },
    getParcel() {
      this.loading = true;
      Parcels.details(this.selectedParcelId)
        .then(res => {
          this.parcel = res;
          this.loading = false;
        })
        .catch(err => {
          if (err.statusCode === Http.NOT_FOUND) {
            this.$store.commit("setDisplayNotFound", true);
          } else {
            this.$captureError(err);
            this.loading = false;
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
#title {
  color: $nero;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
}

.title {
  font-size: 14px;
  font-weight: 600;
}

.parcel-detail-flex {
  padding-left: 46px;
}

.parcel-detail-tabs {
  padding: 24px 24px 0 24px;
  margin-left: 24px;
}

@include media-breakpoint-down(lg) {
  .parcel-detail-tabs {
    margin-left: 0 !important;
    margin-top: 24px;
  }
}
</style>
