import { render, staticRenderFns } from "./ParcelEvidence.vue?vue&type=template&id=c88a0ab0&scoped=true&"
import script from "./ParcelEvidence.vue?vue&type=script&lang=js&"
export * from "./ParcelEvidence.vue?vue&type=script&lang=js&"
import style0 from "./ParcelEvidence.vue?vue&type=style&index=0&id=c88a0ab0&lang=scss&scoped=true&"
import style1 from "./ParcelEvidence.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c88a0ab0",
  null
  
)

export default component.exports