<template>
  <div
    class="shipping-label-parcel d-flex justify-content-center align-items-center border rounded p-4"
  >
    <loading-spinner v-if="loading"></loading-spinner>
    <div
      v-else
      :class="{ 'border rounded shadow': shippingLabel }"
      class="label-wrapper position-relative text-center d-flex justify-content-center align-items-center overflow-hidden"
    >
      <img v-if="shippingLabel" :src="shippingLabel" class="w-100" />
      <img
        v-else
        src="@/assets/img/shipping-label.png"
        class="placeholder-img w-100"
      />
      <div v-if="shippingLabel" id="btn-download" class="position-absolute">
        <z-button
          v-tooltip.bottom="'Exportar'"
          variant="secondary"
          fit="auto"
          class="text-dark mr-3 mb-0 h6"
          @click="downloadFile"
        >
          <font-awesome-icon icon="download" />
        </z-button>
      </div>
      <div v-else id="btn-show" class="position-absolute">
        <z-button
          variant="secondary"
          fit="auto"
          class="text-dark mr-3 mb-0 h6"
          @click="getShippingLabel"
        >
          Mostrar
        </z-button>
      </div>
    </div>
  </div>
</template>

<script>
import Parcels from "@/services/parcels";

export default {
  name: "ShippingLabelParcel",

  props: {
    parcelId: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      loading: false,
      shippingLabel: null
    };
  },

  methods: {
    getShippingLabel() {
      this.loading = true;
      Parcels.shippingLabel(this.parcelId)
        .then(({ base64Label }) => {
          this.shippingLabel = `data:image/png;base64,${base64Label}`;
        })
        .catch(error => {
          this.$captureError(error);
        })
        .finally(() => (this.loading = false));
    },
    downloadFile() {
      let a = document.createElement("a");
      a.href = this.shippingLabel;
      a.download = `guia-paquete-${this.parcelId}.png`;
      a.click();
    }
  }
};
</script>

<style lang="scss">
.shipping-label-parcel {
  background-color: $ghost-white;

  #btn-download {
    top: 1em;
    right: 0px;
  }

  #btn-show {
    top: 1em;
    right: 0px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .placeholder-img {
    filter: blur(8px);
  }
}

@include media-breakpoint-up(md) {
  .shipping-label-parcel,
  .label-wrapper {
    height: 100%;
  }
}
</style>
