<template>
  <div class="py-3">
    <p class="font-weight-bold text-lg mt-2">
      Mapa
    </p>
    <z-map
      id="parcel-map"
      class="parcel-detail-map rounded"
      :destinations="destinations"
    >
    </z-map>
  </div>
</template>

<script>
import ZMap from "@zubut/common/src/components/ZMap";

export default {
  name: "ReservationsDetailMap",

  components: {
    ZMap
  },

  props: {
    origin: {
      type: Object,
      default: null
    },
    destination: {
      type: Object,
      default: null
    }
  },

  computed: {
    destinations() {
      const destinations = [];

      destinations.push({
        order: 0,
        name: this.origin.name,
        position: this.origin.position
      });

      if (this.destination) {
        destinations.push({
          name: this.destination.name,
          position: this.destination.position
        });
      }
      return destinations;
    }
  }
};
</script>

<style lang="scss" scoped>
.parcel-detail-map {
  overflow: hidden;
  width: 100%;
  height: 600px;
}
</style>
