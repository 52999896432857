<template>
  <div class="z-user-info">
    <div data-test-id="title" class="title">
      {{ title }}
    </div>
    <div
      data-test-id="interactive-element"
      class="d-flex wrap-user rounded"
      @click="$emit('click')"
    >
      <z-profile-image size="sm" :type="type" :image="image" />
      <div class="ml-3">
        <div data-test-id="name" class="font-weight-bold">
          {{ name }}
        </div>
        <div data-test-id="phone" class="phone mt-1">
          {{ phone }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ZProfileImage from "@/app/components/ZProfileImage";

const types = {
  USER: "user",
  DRIVER: "driver",
  WAREHOUSE: "warehouse"
};

export default {
  name: "ZUserInfo",

  components: { ZProfileImage },

  props: {
    name: {
      type: String,
      required: true
    },
    phone: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: "user",
      validator: val =>
        [types.USER, types.DRIVER, types.WAREHOUSE].indexOf(val) > -1
    },
    image: {
      type: String,
      default: ""
    }
  },

  computed: {
    title() {
      switch (this.type) {
        case types.USER:
          return "Cliente";
        case types.DRIVER:
          return "Mensajero";
        case types.WAREHOUSE:
          return "Bodega";
      }
      return "";
    }
  }
};
</script>

<style lang="scss" scoped>
.z-user-info {
  font-size: 12px;

  .wrap-user {
    padding: 0.5rem;

    &:hover {
      background-color: $white-smoke;
      cursor: pointer;
    }
  }

  .title {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 1em;
  }

  .phone {
    color: rgba($black, 0.4);
  }
}
</style>
