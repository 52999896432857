<template>
  <div>
    <quick-message
      class="mb-3"
      with-icon
      :show.sync="confirmation"
      type="success"
      message="Link copiado al portapapeles"
    />
    <div class="d-flex flex-column flex-md-row justify-content-md-between">
      <div class="d-flex">
        <service-type-avatar
          :type="ServiceType.NUM_PARCEL_DELIVERY"
          size="lg"
          class="mr-3"
        />
        <div class="d-flex mb-3 mb-md-0">
          <div class="parcel-info d-flex flex-column">
            <div class="d-md-flex align-items-md-center mb-3 mb-md-2">
              <div id="title">Paquete {{ shortParcelId }}</div>
              <status-parcel
                v-if="parcel"
                :status="parcel.status"
                class="ml-md-3"
              />
            </div>
            <div v-if="externalId">External ID: {{ externalId }}</div>
            <div v-if="createdAt">
              Fecha estimada de entrega: {{ createdAt }}
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column flex-md-row">
        <div class="p-1 mx-1">
          <z-button variant="secondary" @click="$emit('show-evidence')">
            Ver bitácora
          </z-button>
        </div>
        <div v-if="parcelId">
          <z-tracking-link
            :id="parcelId"
            is-parcel
            shorten-url
            variant="button"
            @copy-success="copied()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ZTrackingLink from "@zubut/common/src/components/ZTrackingLink";
import StatusParcel from "@zubut/common/src/components/StatusParcel";
import ServiceType from "@zubut/common/src/constants/services/type";
import ServiceTypeAvatar from "@zubut/common/src/components/ServiceTypeAvatar";
import { makeShortUUID } from "@/utils/strings";
import { formatISO } from "@zubut/common/src/utils/time";

export default {
  name: "ParcelDetailHeader",

  components: {
    StatusParcel,
    ZTrackingLink,
    ServiceTypeAvatar
  },

  props: {
    parcel: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      ServiceType,
      confirmation: false
    };
  },

  computed: {
    createdAt() {
      if (this.parcel?.deliveryDate) {
        return formatISO(this.parcel.deliveryDate, "d 'de' MMM, yyyy p aaa");
      }
      return "";
    },
    externalId() {
      return this.parcel?.externalId || "";
    },
    parcelId() {
      return this.parcel?.id || "";
    },
    shortParcelId() {
      return this.parcelId ? makeShortUUID(this.parcelId) : "";
    }
  },

  methods: {
    copied() {
      this.confirmation = true;
    }
  }
};
</script>

<style lang="scss" scoped>
#title {
  color: $nero;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
}

.parcel-info {
  p {
    font-size: 12px;
  }
}

.refresh-btn {
  width: 100%;
}

@include media-breakpoint-up(md) {
  .refresh-btn {
    width: 122px;
  }
}
</style>
