<template>
  <z-modal :show="show" @hide="$emit('update:show', $event)">
    <div class="d-flex justify-content-between mb-4">
      <div>
        <img class="logo" src="@/assets/img/zubut-black.png" />
      </div>
      <z-button class="d-print-none" @click="print">
        <font-awesome-icon icon="print" size="lg" />
      </z-button>
    </div>
    <div class="mb-3">Historial > ID: {{ shortParcelId }}</div>
    <div class="text-3xl font-weight-bold">ID {{ shortParcelId }}</div>
    <div class="mb-4">{{ creationDate }}</div>
    <loading-spinner v-if="loading"></loading-spinner>
    <b-row v-else>
      <b-col md="6">
        <b-row>
          <b-col cols="12">
            <div class="border rounded p-4">
              <div
                class="mb-3 d-flex justify-content-between align-items-center"
              >
                <div class="font-weight-bold">Detalle del paquete</div>
                <div>
                  <status-parcel :status="parcel.status" />
                </div>
              </div>
              <z-user-info
                type="user"
                :name="parcel.client.name"
                phone=""
                class="mb-3"
              />
              <div class="mb-3">
                <div class="font-weight-bold mb-3">Origen</div>
                <div class="d-flex">
                  <div>
                    <font-awesome-icon icon="hexagon-bullet" size="lg" />
                  </div>
                  <div>
                    <div class="font-weight-bold">{{ parcel.origin.name }}</div>
                    <div>{{ parcel.origin.address }}</div>
                  </div>
                </div>
              </div>
              <div>
                <div class="font-weight-bold mb-3">Destino</div>
                <div class="d-flex">
                  <div>
                    <font-awesome-icon icon="hexagon-bullet" size="lg" />
                  </div>
                  <div>
                    <div class="font-weight-bold">
                      {{ parcel.destination.name }}
                    </div>
                    <div>{{ parcel.destination.address }}</div>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
          <b-col cols="12" class="mt-4">
            <parcel-map
              v-if="parcel"
              :selected-position="selected.position"
              :parcel-id="parcelId"
              :origin="parcel.origin"
              :destination="parcel.destination"
            />
          </b-col>
          <b-col cols="12" class="mt-4">
            <div v-if="deliveryProof" class="border rounded p-4">
              <div class="delivery-proof-columns mb-3">
                <div class="w-100">
                  <div class="font-weight-bold mb-3">
                    Evidencias de entrega
                  </div>
                  <div class="font-weight-bold">Cliente</div>
                  <div>Recibió: {{ receiverText }}</div>
                  <div>Nombre: {{ deliveryProof.receiverName }}</div>
                  <div v-if="signingTime">
                    Firma de entrega el:
                    {{ signingTime }}
                  </div>
                </div>
                <div v-if="deliveryProof.signature" class="w-100">
                  <img
                    class="signature"
                    :src="`data:image/png;base64,${deliveryProof.signature}`"
                  />
                </div>
              </div>

              <img
                v-if="deliveryProof.imageUrl"
                :src="deliveryProof.imageUrl"
                alt="evidencia"
                class="deliveryProof-image"
              />
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="6">
        <b-row>
          <b-col cols="12" class="mb-4">
            <div class="border rounded p-4">
              <div class="font-weight-bold">Registros de viaje</div>
              <loading-spinner v-if="loadingLogs" />
              <div v-else>
                <div
                  v-for="log in parsedLogs"
                  :key="log.id"
                  class="d-flex justify-content-between border-bottom mb-2 pt-1 pb-2"
                >
                  <div>
                    {{ log.eventName }}
                  </div>
                  <div>
                    {{ log.createdAt }}
                  </div>
                </div>
              </div>
            </div>
          </b-col>
          <b-col cols="12">
            <div class="border rounded p-4">
              <div class="font-weight-bold">Etiqueta</div>
              <loading-spinner v-if="loadingShippingLabel"></loading-spinner>
              <div v-else class="py-4 px-5">
                <img
                  v-if="shippingLabel"
                  :src="shippingLabel"
                  class="w-100 border"
                />
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </z-modal>
</template>

<script>
import ParcelMap from "./ParcelMap";
import ZUserInfo from "@/app/components/ZUserInfo";
import ZModal from "@zubut/common/src/components/ZModal";
import Logs from "@/constants/parcels/logs";
import Parcels from "@/services/parcels";
import { makeShortUUID } from "@/utils/strings";
import { formatISO } from "@zubut/common/src/utils/time";
import StatusParcel from "@zubut/common/src/components/StatusParcel";
import ReceiverType from "@zubut/common/src/constants/proofOfDelivery/receiver";

export default {
  name: "ParcelEvidence",

  components: {
    ParcelMap,
    StatusParcel,
    ZModal,
    ZUserInfo
  },

  props: {
    show: {
      type: Boolean,
      default: false
    },
    parcelId: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      warehouse: null,
      showModal: true,
      parcel: null,
      loading: true,
      loadingShippingLabel: false,
      loadingLogs: false,
      logs: [],
      logsDestination: [{ lat: 0, lng: 0 }],
      selected: { mensaje: "", position: { lat: 0, lng: 0 } },
      shippingLabel: ""
    };
  },

  computed: {
    parsedLogs() {
      return this.logs.map(log => ({
        id: log.id,
        shortId: makeShortUUID(log.id),
        createdAt: formatISO(log.createdAt, "d 'de' MMM, u hh:mm a"),
        event: log.event,
        eventName: Logs.get[log.event],
        driver: log.driver
      }));
    },
    shortParcelId() {
      return makeShortUUID(this.parcelId);
    },
    creationDate() {
      return this.parcel?.createdAt
        ? formatISO(
            this.parcel.createdAt,
            "'Creado el' d 'de' MMM, 'de' u h:mm a"
          )
        : "";
    },
    deliveryProof() {
      return this.parcel?.deliveryProof;
    },
    receiverText() {
      if (this.deliveryProof) {
        return ReceiverType.get[this.deliveryProof.receiverType];
      }
      return "";
    },
    signingTime() {
      let signingTime = "";
      this.parsedLogs.forEach(parcedLog => {
        if (parcedLog.event === Logs.NUM_DELIVERED) {
          signingTime = parcedLog.createdAt;
        }
      });
      return signingTime;
    }
  },

  created() {
    this.getParcel();
    this.getShippingLabel();
    this.getLogs();
  },

  methods: {
    getParcel() {
      this.loading = true;
      Parcels.details(this.parcelId)
        .then(res => {
          this.parcel = res;
        })
        .catch(err => {
          this.$captureError(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getShippingLabel() {
      this.loadingShippingLabel = true;
      Parcels.shippingLabel(this.parcelId)
        .then(({ base64Label }) => {
          this.shippingLabel = `data:image/png;base64,${base64Label}`;
        })
        .catch(error => {
          this.$captureError(error);
        })
        .finally(() => {
          this.loadingShippingLabel = false;
        });
    },
    getLogs() {
      this.loadingLogs = true;
      Parcels.logs(this.parcelId, {
        filter: {
          skip: 0,
          limit: 50,
          order: "createdAt asc"
        }
      })
        .then(res => {
          this.logs = res.data;
        })
        .finally(() => {
          this.loadingLogs = false;
        });
    },
    print() {
      setTimeout(() => {
        window.print();
      }, 100);
    }
  }
};
</script>

<style lang="scss" scoped>
.delivery-proof-columns {
  display: flex;
  gap: 30px;
  justify-content: space-between;
}
.deliveryProof-image {
  display: block;
  width: 300px;
  margin: 0 auto;
}
.signature {
  width: 100%;
}
.map {
  height: 500px;
  width: 100%;
  padding: 16px;
  border-radius: 10px;
  overflow: hidden;
}

.logo {
  width: 140px;
}
@media print {
  .deliveryProof-image {
    display: none;
  }
}
</style>

<style lang="scss">
@media print {
  .modal-backdrop {
    background-color: white !important;
    opacity: 1;
  }
}
</style>
