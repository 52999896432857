<template>
  <z-map
    v-if="destination"
    id="parcel-detail-map"
    class="map border rounded "
    :center="origin.position"
  >
    <l-marker :lat-lng="origin.position" :icon="pinStoreIcon">
      <l-popup>Origen</l-popup>
    </l-marker>
    <l-marker
      v-if="warehouse"
      :lat-lng="warehouse.position"
      :icon="pinWarehouseIcon"
    >
      <l-popup>Bodega</l-popup>
    </l-marker>
    <l-marker :lat-lng="destination.position" :icon="pinEndIcon">
      <l-popup>Destino</l-popup>
    </l-marker>

    <l-marker
      v-for="(ldestination, index) in logsDestination"
      :key="`ldestination-${index}`"
      :visible="visibleLog"
      :lat-lng="ldestination"
      :icon="selectedPosition === ldestination ? logSelectIcon : logIcon"
    />
  </z-map>
</template>

<script>
import { LMarker, LPopup } from "vue2-leaflet";
import MapMixin from "@/mixins/map";
import ZMap from "@zubut/common/src/components/ZMap";

export default {
  name: "ParcelMap",

  components: {
    LMarker,
    LPopup,
    ZMap
  },

  mixins: [MapMixin],

  props: {
    destination: {
      type: Object,
      default: null
    },
    logsDestination: {
      type: Array,
      default: () => []
    },
    warehouse: {
      type: Object,
      default: null
    },
    origin: {
      type: Object,
      default: null
    },
    selectedPosition: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      logIcon: this.makeIcon(require("@/assets/img/ic_log.png"), {
        size: [18, 18],
        popupAnchor: [-3, -76]
      }),
      logSelectIcon: this.makeIcon(require("@/assets/img/ic_log_select.png"), {
        size: [18, 18],
        popupAnchor: [-3, -76]
      }),
      pinEndIcon: this.makeIcon(require("@/assets/img/ic_pin_end.png"), {
        size: [34, 40],
        popupAnchor: [-3, -76]
      }),
      pinStartIcon: this.makeIcon(require("@/assets/img/ic_pin_start.png"), {
        size: [34, 40],
        popupAnchor: [-3, -76]
      }),
      pinStoreIcon: this.makeIcon(require("@/assets/img/ic_pin_store.png"), {
        size: [34, 40],
        popupAnchor: [-3, -76]
      }),
      pinWarehouseIcon: this.makeIcon(
        require("@/assets/img/ic_pin_warehouse.png"),
        {
          size: [34, 40],
          popupAnchor: [-3, -76]
        }
      )
    };
  },

  computed: {
    visibleLog() {
      if (this.logsDestination[0].lat != 0) return true;
      return false;
    }
  }
};
</script>
